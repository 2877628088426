<template>
  <v-dialog v-model="open" :persistent="persistent" max-width="50rem" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title v-if="headline" class="headline">{{ $vuetify.lang.t(headline) }}</v-card-title>

      <v-card-text>
        <p class="black--text" v-if="subheading">{{ $vuetify.lang.t(subheading) }}</p>

        <iframe
          :src="`${docLink}?embedded=true`"
        ></iframe>
        <a
          :href="docLink"
          target="_blank"
          class="document-link text-caption"
          >{{ $vuetify.lang.t('$vuetify.tosDialog.openInNewWindow') }}</a
        >
      </v-card-text>


      <v-card-actions v-if="showActions">
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          text
          :disabled="loading"
          :loading="action === disagree"
          @click="onDisagree"
        >{{ $vuetify.lang.t(disagree) }}</v-btn>
        <v-btn
          color="green darken-1"
          text
          :disabled="loading"
          :loading="action === agree"
          @click="onAgree"
        >{{ $vuetify.lang.t(agree) }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
iframe {
  width: 100%;
  border: 1px #e2e2e2 solid;
  height: 35vh;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: 50vh;
  }
}
.document-link {
  color: var(--v-link-base);
}
</style>

<script>
import { AUTH_LOGOUT } from '@/store/actions/auth';

export default {
  name: 'TosDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    docLink: {
      type: String,
      default: 'https://docs.google.com/document/d/e/2PACX-1vSixWDXP_AEIO1G1x9nNKW2hP_mvL1PsGe4hJdsTpW_NH_zkihpeHeB9dJBDsxhjKNR5xh5rxGzAVhq/pub'
    },

    headline: {
      type: String,
      default: '$vuetify.tosDialog.heading',
    },
    subheading: {
      type: String,
      default: '$vuetify.tosDialog.subheading',
    },
  },

  data() {
    return {
      disagree: 'Log out',
      agree: 'Agree',
      loading: false,
      action: null,
    };
  },
  computed: {
    open: {
      get() { return this.value; },
      set(val) { this.$emit( 'input', val); },
    },
  },

  methods: {
    async onAgree() {
      this.loading = true;
      this.action = this.agree;

      await this.$store.dispatch('userProfile/updateTos', new Date().toISOString());

      this.loading = false;
    },
    async onDisagree() {
      this.loading = true;
      this.action = this.disagree;
      await this.$store.dispatch(AUTH_LOGOUT);
      this.loading = false;
    }
  },
};
</script>
